import {useEffect, useMemo, useState} from "react";

interface KeyValuePair<A extends any, B extends any> {
    key: A
    value: B
}

function useOuterKeyValuePairState<B=any> (defaultValues: Record<string,B>, tag: string, name: string, setState: Function) {
    const localTransform = (data: Record<string,B>) => Object.assign({}, Object.keys(data).map((x:string) => ({key: x, value: data[x]})))
    const [local, setLocal] = useState<Record<number,KeyValuePair<string, B>>>(localTransform(defaultValues))
    useMemo(() => {
        Object.keys(local).length === 0 && Object.keys(defaultValues).length !== 0 && setLocal(localTransform(defaultValues))
    }, [defaultValues])
    useEffect(() => {
        setState((a: any) => ({...a, [tag]: {...a[tag], [name]: Object.values(local).reduce((prev, curr) => ({...prev, [curr.key]: curr.value}), {})}}))
    }, [local])
    const updateLocal = (f: Function | any) => {
        setLocal(a => {
            const data = typeof f === "function" ? f(a) : f
            return ({...a, ...data})
        })
    }
    const addElement = () => {
        setLocal((a: any) => ({...a, [Object.keys(a).map(b => parseInt(b)).reduce((prev, curr) => curr > prev ? curr : prev, 0) + 1]: {key: "", value: ""}}))
    }
    const deleteElement = (index: string) => {
        setLocal((a: any) => {
            const {[index]: _, ...newA} = a
            return newA
        })
    }
    return {
        local, setLocal, updateLocal, addElement, deleteElement}
}

function useGeneralOuterState<T extends any[] | Record<string, any>> (defaultValues: T, tag: string, name: string, setState: Function) {
    const [local, setLocal] = useState<T>(defaultValues)
    useMemo(() => local.length === 0 && defaultValues.length !== 0 && setLocal(defaultValues), [JSON.stringify(defaultValues)])
    useEffect(() => {
        setState((a: any) => ({...a, [tag]: {...a[tag], [name]: local}}))
    }, [local])
    const updateLocal = (f: Function | T) => {
        setLocal((a: any) => {
            const data = typeof f === "function" ? f(a) : f
            if (Array.isArray(a)) {
                return Object.values(data)
            } else {
                return ({...a, ...data})
            }

        })
    }
    return {local, setLocal, updateLocal}
}

function useOuterState<T extends Record<string, any>>(defaultValues: T, tag: string, name: string, setState: Function) {
    return useGeneralOuterState<T>(defaultValues, tag, name, setState)
}

function useOuterArrayState<T=any> (defaultValues: T[], tag: string, name: string, setState: Function) {
    return useGeneralOuterState<T[]>(defaultValues, tag, name, setState)
}

export {useOuterKeyValuePairState, useOuterState, useOuterArrayState}
